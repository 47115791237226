// src/components/FileUpload.tsx

import React, { useState } from 'react';
import Papa from 'papaparse';

interface Props {
    onFilesParsed: (uniqueAttendees: string[], allAttendees: string[], fileCount: number) => void;
}

export const FileUpload: React.FC<Props> = ({ onFilesParsed }) => {

    const [showUploadAlert, setShowUploadAlert] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        // Use a Set for unique attendees.
        let uniqueAttendeesSet = new Set<string>();
        // Use an array for all attendees.
        let allAttendeesList: string[] = [];

        if (files) {
            const fileArray = Array.from(files);

            if (fileArray.length !== 12 && fileArray.length !== 13) {
                allAttendeesList = [];
                uniqueAttendeesSet = new Set<string>();
                onFilesParsed(Array.from(uniqueAttendeesSet), allAttendeesList, fileArray.length);
                setShowUploadAlert(true);
                setTimeout(() => setShowUploadAlert(false), 3000);
                return;
            }

            let filesProcessed = 0;

            fileArray.forEach(file => {
                Papa.parse(file, {
                    delimiter: '\t', // Using tab as the delimiter
                    skipEmptyLines: true,
                    complete: (results) => {
                        const data = results.data as string[][];

                        // Process data starting from row 11, collecting both all and unique entries.
                        for (let i = 9; i < data.length; i++) {
                            if (data[i][0] !== "3. In-Meeting Activities") {
                                allAttendeesList.push(data[i][0]);
                                uniqueAttendeesSet.add(data[i][0]);
                            } else {
                                break;
                            }
                        }

                        filesProcessed++;
                        // Check if all files have been processed before calling onFilesParsed
                        if (filesProcessed === fileArray.length) {
                            onFilesParsed(Array.from(uniqueAttendeesSet), allAttendeesList, fileArray.length);
                        }
                    }
                });
            });
        }
    };

    return (
        <div className="mb-3">
            <label htmlFor="file-upload" className="form-label">Upload CSV Files</label>
            <input
                type="file"
                className="form-control"
                id="file-upload"
                multiple
                onChange={handleFileChange}
                accept='.csv'
            />
            {showUploadAlert && <div className="alert alert-danger mt-3 mb-5" role="alert">
                You can only upload 12 or 13 files!
            </div>}
        </div>
    );
};

export default FileUpload;
