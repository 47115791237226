import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { FileUpload } from '../FileUploadPage/FileUploadPage';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [uniqueAttendees, setUniqueAttendees] = useState<string[]>([]);
  const [attendeeCounts, setAttendeeCounts] = useState<{ [key: string]: number }>({});
  const [numFiles, setNumFiles] = useState(0);

  const handleFilesParsed = (unique: string[], all: string[], filesCount: number) => {
    setUniqueAttendees(unique);
    countAttendeeFrequencies(all);
    setNumFiles(filesCount);
  };

  const countAttendeeFrequencies = (all: string[]) => {
    const counts: { [key: string]: number } = {};
    all.forEach(attendee => {
      counts[attendee] = (counts[attendee] || 0) + 1;
    });
    setAttendeeCounts(counts);
  };

  const calculatePoints = (count: number) => {
    const multiplier = numFiles === 12 ? 0.42 : numFiles === 13 ? 0.38 : 0;
    return Math.round(count * multiplier);
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(uniqueAttendees.map(attendee => ({
      Name: attendee.split('-')[0].trim(),
      Index: +(attendee.split('-').slice(1).join(' - ').trim()),
      ClassesAttended: attendeeCounts[attendee],
      Points: calculatePoints(attendeeCounts[attendee])
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Prisustvo");

    XLSX.writeFile(wb, "Prisustvo.xlsx");
  };

  return (
    <div className="container mt-4">
      <FileUpload onFilesParsed={(unique, all, fileCount) => handleFilesParsed(unique, all, fileCount)} />
      {uniqueAttendees.length > 0 && <button className="btn btn-success mb-3" onClick={handleDownload}>Download <FontAwesomeIcon icon={faDownload} /></button>}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Index</th>
            <th>Classes Attended</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {uniqueAttendees.map((attendee, index) => {
            const parts = attendee.split('-').map(part => part.trim());
            return (
              <tr key={`unique-${index}`}>
                <td>{parts[0]}</td>
                <td>{parts.slice(1).join(' - ')}</td>
                <td>{attendeeCounts[attendee]}</td>
                <td>{calculatePoints(attendeeCounts[attendee])}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
